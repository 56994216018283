import styled from "styled-components"

export const CarouselText = styled.div`
  font-family: "Roboto";
  font-style: normal;
  text-align: left;
  .top-text {
    font-weight: 900;
    font-size: 68px;
    line-height: 72px;
    color: #FFFFFF;
    @media (max-width: 992px) {
      font-size: 50px;
      line-height: 60px;
    }
    @media (max-width: 768px) {
      font-size: 40px;
      line-height: 50px;
    }
  }
  .bottom-text {
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    padding-top: 16px;
    color: #FFFFFF;
    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow: hidden;
    @media (max-width: 768px) {
      font-size: 18px;
      line-height: 24px;
    }
  }
`
export const CarouselItems = styled.div`
  margin-left: 10px;
  margin-right: 10px;
`
export const CarouselCard = styled.div`
  padding: 64px;
  width: 568px;
  height: 426px;
  background:  #D0D5DD;
  border-radius: 8px;
  transition: 0.4s;
  @media (max-width: 774.98px) {
    width: 506px;
    height: 486px;
  }
  @media (max-width: 576px) {
    padding: 48px;
    width: 100%;
    max-width: 370px;
  }
`
export const TopWrapper = styled.div`
  text-align: center;
  margin-bottom: 16px;
  font-family: "Roboto";
  font-style: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Subheading = styled.div`
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  padding-top: 24px;
  color: #475467;
  margin-left: 35px;
  margin-right: 35px;
  @media (max-width: 774.98px) {
    font-size: 18px;
  }
  p {
    max-width: 800px;
    color: #475467;
  }
`

export const CarouselWrapper = styled.div`
  margin: 0 auto;
  padding: 96px 0 151px;

  @media (max-width: 992px) {
    padding: 30px 0 80px;
  }

  @media (max-width: 576px) {
    padding: 25px 0 80px;
  }
`

export const ComponentWrapper = styled.div`
  font-family: "Roboto", sans-serif;
  overflow:hidden;
  @media (max-width: 435.98px) {
    padding: 48px 15px;
  }
  background: ${props =>
    props.background === "white" ? "#FFFFFF" : "#F2F4F7"};
  padding: 64px 0;
  .slick-slider {
    .slick-dots {
      bottom: -78px;
    }
    .slick-arrow-btn {
      margin: 0 12px;
    }
  }

  .slick-slider {
    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 200px;
      background: linear-gradient(to right, ${props =>
        props.background === "white" ? "#FFFFFF" : "#F2F4F7"},rgba(255,255,255,0));
      top: 0;
      left: 0;
      z-index: 1;
      @media (max-width: 434.98px) {
        width: 30px;
      }
      @media only screen and (max-width: 689.98px) and (min-width: 435px) {
        width: 80px;
      }
    }
    &:after {
      content: '';
      position: absolute;
      height: 100%;
      width: 200px;
      background: linear-gradient(to left, ${props =>
        props.background === "white" ? "#FFFFFF" : "#F2F4F7"},rgba(255,255,255,0));
      top: 0;
      right: 0;
      z-index: 1;
      @media (max-width: 434.98px) {
        width: 30px;
      }
      @media only screen and (max-width: 689.98px) and (min-width: 435px) {
        width: 80px;
      }
    }
    .slick-list {
      @media (max-width: 774.98px) {
        height: 400px;
      }
    }
    .slick-center .card-bg {
      background: #FFFFFF;
    }
    .slick-center .top-text {
      color: #002DC2;
    }
    .slick-center .bottom-text{
      color: #475467;
    }
    .slick-dots {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;

      li {
        width: 10px;
        height: 10px;
        margin: 0 8px;

        button {
          width: 10px;
          height: 10px;
          padding: 0;

          &:before {
            width: 10px;
            height: 10px;
            line-height: 10px;
            font-size: 10px;
          }
        }
      }
    }

    .slick-arrow-btn {
      width: 30px;
      height: 30px;
      background: #0022b7;
      border-radius: 50%;
      margin: 0 32px;
      cursor: pointer;

      &:before {
        display: block;
        content: " ";
        background-image: url("/icons/chevron-right-white-thin.svg");
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
      }
      &.slick-prev-btn {
        &:before {
          transform: rotate(180deg);
        }
      }
      &.slick-next-btn {
      }
    }
  }
`
