import React from "react"
import { Link } from "gatsby"
import OptimizedImage from "../../../utils/optimizedImage"
import { switchbackResolveRichText } from '@utils/resolve-rich-text/switchbackResolveRichText';
import {   
  Item,
  ItemWrapper,
  ItemImage,
  ItemText,
  ItemHeading,
  ItemSubheading,
  ItemInlineText, 
} from "../styles/CarouselSmall.styled"

const SmallCarouselItem = ({ item, backgroundColor }) => {
  const align = item?.align === "center" ? "center" : "left"
  if (item?.callsToAction) {
    return (
      <Item>
        <Link className="link-wrapper"to={item?.callsToAction[0]?.link}>
          <ItemWrapper className="clickable">
            <ItemImage background={backgroundColor}>
              <OptimizedImage image={item.featuredImage?.gatsbyImageData} src={item.featuredImage?.file?.url} alt={item?.heading || "featured image"}/>
            </ItemImage>
            <ItemText style={{textAlign: align}} >
              { item?.heading && <ItemHeading>{item?.heading}</ItemHeading> } 
              { item?.subheading && <ItemSubheading>{switchbackResolveRichText(item?.subheading)}</ItemSubheading> }
              { item?.callsToAction && item?.callsToAction.map((cta, index) => (
                <ItemInlineText className="link" key={index}>{cta?.label}</ItemInlineText>
              ))}
            </ItemText>
          </ItemWrapper>
        </Link>
      </Item>
    )
  } else {
    return (
      <Item>
        <ItemWrapper>
          <ItemImage background={backgroundColor}>
            <OptimizedImage image={item.featuredImage?.gatsbyImageData} src={item.featuredImage?.file?.url} alt={item?.heading || "featured image"}/>
          </ItemImage>
          <ItemText style={{textAlign: align}} >
            { item?.heading && <ItemHeading>{item?.heading}</ItemHeading> } 
            { item?.subheading && <ItemSubheading>{switchbackResolveRichText(item?.subheading)}</ItemSubheading> }
          </ItemText>
        </ItemWrapper>
      </Item>
    )
  }
}

export default SmallCarouselItem