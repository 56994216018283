import React, { createRef } from "react"
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { switchbackResolveRichText } from '@utils/resolve-rich-text/switchbackResolveRichText';
import {
  ImageCarouselWrapper,
  Container,
  HeaderContainer,
  Kicker,
  Heading,
  Subheading,
  Description,
  MainContainer,
} from './styles/CarouselSmall.styled';
import SmallCarouselItem from "./components/CarouselSmallItem";
import onKeyDown from "../../utils/onKeyDown";

export const CarouselSmall = ({ component }) => {
  const {
    heading,
    subheadingTest,
    subheading,
    carouselItems,
    background,
    kicker,
    noTopPadding,
    noBottomPadding,
  } = component;
  const sliderRef = createRef();

  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    autoplay: false,
    draggable: true,
    swipeToSlide: true,
    variableWidth: true,
    speed: 1000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      }
    ],
    appendDots: dots => (
      <div>
        <div className="slick-arrow-btn slick-prev-btn" onClick={() => sliderRef?.current?.slickPrev()} role="button" aria-label="previous" tabIndex={0} onKeyDown={(e) => onKeyDown(e, () => sliderRef?.current?.slickPrev())} />
        <div className="slick-arrow-btn slick-next-btn" onClick={() => sliderRef?.current?.slickNext()} role="button" aria-label="next" tabIndex={0} onKeyDown={(e) => onKeyDown(e, () => sliderRef?.current?.slickNext())} />
      </div>
    ),
  };

  const backgroundColor = background === "grey" ? "#F2F4F7" : "#ffffff"

  return (
    <ImageCarouselWrapper className="image-carousel--wrapper" style={{ background: `${backgroundColor}` }}>
      <Container noTopPadding={noTopPadding} noBottomPadding={noBottomPadding}>
        <HeaderContainer>
          {kicker && <Kicker>{kicker}</Kicker>}
          {heading && <Heading>{heading}</Heading>}
          {subheading?.subheading && <Subheading>{subheading?.subheading}</Subheading>}
          {subheadingTest?.raw && <Description>{switchbackResolveRichText(subheadingTest)}</Description>}
        </HeaderContainer>
        <MainContainer>
          <Slider ref={sliderRef} {...sliderSettings}>
            {carouselItems?.map((item, index) => (
              <SmallCarouselItem item={item} backgroundColor={backgroundColor} key={index} />
            ))}
          </Slider>
        </MainContainer>
      </Container>
    </ImageCarouselWrapper>
  )
}
