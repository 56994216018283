import React from "react"
// import CarouselStandard from './CarouselStandard';
import { CarouselContentBlock } from "./CarouselContentBlock"
import { CarouselTabNavigationColumn } from "./CarouselTabNavigationColumn"
import { CarouselFullWidth } from "./CarouselFullWidth"
import { CarouselSmall } from "./CarouselSmall"
import { CarouselTextCards } from "./CarouselTextCards"
import { CarouselSmallImage } from "./CarouselSmallImage"
import { CarouselIconVariant } from "./CarouselIconVariant"

const Carousel = ({ component, backgroundColor }) => {
  const { type } = component
  switch (type) {
    case "content-blocks":
      return <CarouselContentBlock component={component} />
    case "tab-navigation-column":
      return <CarouselTabNavigationColumn component={component} />
    case "icon-variant":
      return <CarouselIconVariant component={component} />
    case "full-width":
      return <CarouselFullWidth component={component} />
    case "small":
      return (
        <CarouselSmall
          component={component}
        />
      )
    case "text-cards":
      return (
        <CarouselTextCards
          component={component}
          backgroundColor={backgroundColor}
        />
      )
    case "images-small-cubes":
      return (
        <CarouselSmallImage
          component={component}
          backgroundColor={backgroundColor}
        />
      )
    default:
      return <CarouselContentBlock component={component} />
  }
}

export default Carousel
