import styled, { css } from "styled-components"

const ImageCarouselWrapper = styled.div`
  font-family: "Roboto", sans-serif;
  overflow:hidden;
  &.image-carousel--full-width-wrapper {
    .image-carousel--container {
      margin: 0 auto;
      padding: 96px 0 151px;

      @media (max-width: 992px) {
        padding: 56px 0 135px;
      }

      @media (max-width: 576px) {
        padding: 40px 0 119px;
      }
      ${({ noTopPadding, noBottomPadding }) => css`
        padding-top: ${noTopPadding === true && 0};
        padding-bottom: ${noBottomPadding === true && 0};
        @media (max-width: 992px) {
          padding-top: ${noTopPadding === true && 0};
          padding-bottom: ${noBottomPadding === true && 0};
        }
        @media (max-width: 768px) {
          padding-top: ${noTopPadding === true && 0};
          padding-bottom: ${noBottomPadding === true && 0};
        }
      `}
    }

    .image-carousel--main-container {
      width: 100%;

      .image-carousel--item {
        .image-carousel--item-wrapper {
          width: calc(100vw - 222px);
          max-width: 1170px;
          height: 424px;
          padding: 0 24px;
        }

        .image-carousel--item-image {
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          border-radius: 6px;
        }
      }
    }

    @media (max-width: 576px) {
      .image-carousel--main-container {
        .image-carousel--item {
          .image-carousel--item-wrapper {
            width: calc(100vw - 70px);
            padding: 0 12px;
          }
        }
      }
    }

    .slick-slider {
      .slick-list {
        height: 600px;
      }
      .slick-dots {
        bottom: -55px;
      }
    }
  }

  &.image-carousel--wrapper {
    .image-carousel--container {
      margin: 0 auto;
      padding: 96px 0 174px;

      @media (max-width: 992px) {
        padding: 80px 0 158px;
      }

      @media (max-width: 576px) {
        padding: 64px 0 142px;
      }
    }

    .image-carousel--header-container {
      max-width: 870px;
      margin: 0 auto;
      padding: 0 35px;

      .image-carousel--heading {
        font-weight: 700;
        font-size: 38px;
        line-height: 42px;
        text-align: center;
        color: #0a0033;
      }

      .image-carousel--description {
        font-size: 22px;
        line-height: 30px;
        text-align: center;
        color: #555555;
        margin: 16px 0 0;
        a {
          text-decoration: none;
          color: #002dc2;
          font-size: 16px;
          line-height: 64px;
          font-weight: 700;
        }
      }
    }

    .image-carousel--main-container {
      margin: 20px auto 0;
      .image-carousel--item {
        .image-carousel--item-wrapper {
          background: #ffffff;
          border: 1px solid #e4e7ec;
          border-radius: 6px;
          margin: 0 15px;
          width: 370px;
          min-height: 148px;
          padding: 24px 22px;
        }

        .image-carousel--item-image {
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          box-shadow: 0px 4px 30px rgb(54 19 81 / 10%);
          border-radius: 6px;
        }
        .image-carousel--text {
          margin: auto 0;
          .image-carousel--item-title {
            font-weight: 700;
            text-align: left;
            color: #101828;
            font-weight: 700;
            font-size: 22px;
            line-height: 30px;
          }

          .image-carousel--item-description {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: left;
            color: #101828;
            margin: 24px 0 0 0;
            a {
              margin-top: 32px !important;
              color: #002dc2;
              text-decoration: none;
              font-weight: 700;
            }
          }
        }
      }
    }

    @media (max-width: 576px) {
      .image-carousel--header-container {
        .image-carousel--heading {
          font-size: 32px;
          line-height: 42px;
        }

        .image-carousel--description {
          font-size: 16px;
          line-height: 24px;
        }
      }

      .image-carousel--main-container {
        margin: 48px 0 0;
      }
    }

    .slick-slider {
      .slick-dots {
        bottom: -78px;
      }
      .slick-arrow-btn {
        margin: 0 12px;
      }
    }
  }

  .slick-slider {
    .slick-center .card-text {
      color: #002dc2;
      transition: opacity;
      transition-duration: 2s;
      transition-timing-function: ease-in-out;
      transition-delay: 300ms;
      opacity: 1;
    }
    .slick-dots {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;

      li {
        width: 10px;
        height: 10px;
        margin: 0 8px;

        button {
          width: 10px;
          height: 10px;
          padding: 0;

          &:before {
            width: 10px;
            height: 10px;
            line-height: 10px;
            font-size: 10px;
          }
        }
      }
    }

    .slick-arrow-btn {
      width: 30px;
      height: 30px;
      background: #0022b7;
      border-radius: 50%;
      margin: 0 32px;
      cursor: pointer;

      &:before {
        display: block;
        content: " ";
        background-image: url("/icons/chevron-right-white-thin.svg");
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
      }
      &.slick-prev-btn {
        &:before {
          transform: rotate(180deg);
        }
      }
      &.slick-next-btn {
      }
    }
  }
`

export { ImageCarouselWrapper }
