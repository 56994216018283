import styled, { css } from "styled-components"

export const ImageCarouselWrapper = styled.div`
  font-family: "Roboto", sans-serif;
  .slick-slider {
    .slick-dots {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;

      li {
        width: 10px;
        height: 10px;
        margin: 0 8px;

        button {
          width: 10px;
          height: 10px;
          padding: 0;

          &:before {
            width: 10px;
            height: 10px;
            line-height: 10px;
            font-size: 10px;
          }
        }
      }
    }

    .slick-arrow-btn {
      width: 30px;
      height: 30px;
      background: #0022b7;
      border-radius: 50%;
      margin: 0 32px;
      cursor: pointer;

      &:before {
        display: block;
        content: " ";
        background-image: url("/icons/chevron-right-white-thin.svg");
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
      }
      &.slick-prev-btn {
        &:before {
          transform: rotate(180deg);
        }
      }
      &.slick-next-btn {
      }
    }
  }
`

export const Container = styled.div`
  margin: 0 auto;
  padding: 96px 0 174px;

  @media (max-width: 992px) {
    padding: 56px 0 158px;
  }

  @media (max-width: 576px) {
    padding: 40px 0 142px;
  }
  ${({ noTopPadding, noBottomPadding }) => css`
    padding-top: ${noTopPadding === true && 0};
    padding-bottom: ${noBottomPadding === true && 0};
    @media (max-width: 992px) {
      padding-top: ${noTopPadding === true && 0};
      padding-bottom: ${noBottomPadding === true && 0};
    }
    @media (max-width: 768px) {
      padding-top: ${noTopPadding === true && 0};
      padding-bottom: ${noBottomPadding === true && 0};
    }
  `}
`

export const HeaderContainer = styled.div`
  max-width: 870px;
  margin: 0 auto;
  padding: 0 35px;
`

export const Kicker = styled.div`
  color: #002DC2;
  font-weight: 900;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
`

export const Heading = styled.h2`
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  text-align: center;
  color: #0a0033;
  @media (max-width: 992px) {
    font-size: 38px;
    line-height: 48px;
  }
  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 42px;
  }
`

export const Subheading = styled.p`
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  color: #475467;
  margin-top: 16px;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`

export const Description = styled.p`
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  color: #555555;
  margin: 16px 0 0;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
  a {
    text-decoration: none;
    color: #002dc2;
    font-size: 16px;
    line-height: 64px;
    font-weight: 700;
    @media (max-width: 768px) {
      button {
        width: 100%
      }
    }
  }
`

export const MainContainer = styled.div`
  margin: 20px auto 0;
  .slick-slider {
    .slick-track {
      display: flex;
      .slick-slide {
        display: flex;
        height: inherit;
        min-height: unset;
      }
    }
    .slick-dots {
      bottom: -55px;
    }
  }
  @media (max-width: 768px) {
    margin: 48px 0 0;
  }
`

export const Item = styled.div`
  height: 100%;
  a {
    text-decoration: none;
  }
  `

export const ItemWrapper = styled.div`
  background: #ffffff;
  border: 1px solid #e4e7ec;
  border-radius: 6px;
  margin: 0 15px 30px;
  width: 370px;
  padding: 24px 22px;
  height: 100%;
  &.clickable {
    transition: box-shadow ease 0.25s;
    .link {
      transition: color ease 0.25s;
    }
    &:hover {
      box-shadow: 0px 12px 20px 5px rgba(27, 27, 27, 0.15);
      .link {
        color:#5260FF;
      }
    }
  }
  @media (max-width: 400px) {
    width: 300px;
  }
  @media (max-width: 320px) {
    width: 250px;
  }
`

export const ItemImage = styled.div`
  position: relative;
  box-shadow: 0px 4px 30px rgb(54 19 81 / 10%);
  ${({ background }) => background && background !== "#ffffff" && `
    box-shadow: none;
  `}
  .gatsby-image-wrapper {
    border-radius: 6px;
    width: 100%;
  }
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
`

export const ItemText = styled.div`
  margin: auto 0;
  height: fit-content;
  padding: 30px 22px 0px;
`

export const ItemHeading = styled.p`
  font-weight: 700;
  color: #101828;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
`

export const ItemSubheading = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #101828;
  margin: 8px 0 0 0;
  a {
    margin-top: 32px !important;
    color: #002dc2;
    text-decoration: none;
    font-weight: 700;
  }
  p {
    margin-bottom: -19px !important;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`

export const ItemInlineText = styled.div`
  margin-top: 8px;
  color: #002dc2;
  text-decoration: none;
  font-weight: 700;
`
