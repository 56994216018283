import React, { createRef } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { switchbackResolveRichText } from '@utils/resolve-rich-text/switchbackResolveRichText';
import IconVariantCard from './components/IconCarouselCard';
import Heading from '../Heading';
import { CarouselWrap, SliderWrap } from './styles/CarouselIconVariant.styled';
import onKeyDown from "../../utils/onKeyDown";

const handleHeadingSize = (headingSize) => {
  return headingSize?.substring(1) > 2 ? 'Heading 3' : 'Heading 2'
}

export const CarouselIconVariant = ({ component }) => {
  const sliderRef = createRef();

  const headingComponent = {
    backgroundColor: component?.background,
    kicker: component?.kicker,
    heading: component?.heading,
    headingSize: handleHeadingSize(component?.headingSize),
    subheading: component?.subheading,
    noTopPadding: component?.noTopPadding,
  }

  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    autoplay: false,
    draggable: true,
    swipeToSlide: true,
    variableWidth: true,
    speed: 1000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      }
    ],
    appendDots: () => (
      <div>
        <div className="slick-arrow slick-prev" onClick={() => sliderRef?.current?.slickPrev()} role="button" aria-label="previous" tabIndex={0} onKeyDown={(e) => onKeyDown(e, () => sliderRef?.current?.slickPrev())} />
        <div className="slick-arrow slick-next" onClick={() => sliderRef?.current?.slickNext()} role="button" aria-label="next" tabIndex={0} onKeyDown={(e) => onKeyDown(e, () => sliderRef?.current?.slickNext())} />
      </div>
    ),
  };

  return (
    <CarouselWrap bgColor={component?.background?.toLowerCase()} noBottomPadding={component?.noBottomPadding}>
      <Heading component={headingComponent} />
      <SliderWrap>
        <Slider ref={sliderRef} {...sliderSettings}>
          {component?.carouselItems && component.carouselItems.map((item, index) => (
            <IconVariantCard item={item} key={index} />
          ))}
        </Slider>
      </SliderWrap>
    </CarouselWrap>
  )
}
