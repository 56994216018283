import React, { createRef } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import { Heading } from "@atoms"
import { switchbackResolveRichText } from "@utils/resolve-rich-text/switchbackResolveRichText"
import { CarouselText, CarouselItems, CarouselCard, TopWrapper, Subheading, CarouselWrapper, ComponentWrapper, } from "./styles/CarouselTextCards.styled"

export const CarouselTextCards = ({ component }) => {
  const {
    heading,
    headingSize,
    subheading,
    carouselItems,
    autoplay,
    background,
    noTopPadding,
    noBottomPadding,
  } = component
  const sliderRef = createRef()

  const sliderSettings = {
    dots: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    infinite: true,
    autoplay: autoplay === false ? false : true,
    draggable: true,
    swipeToSlide: true,
    variableWidth: true,
    speed: 1000,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      }
    ],
    appendDots: dots => (
      <ul>
        <div
          className="slick-arrow-btn slick-prev-btn"
          onClick={() => sliderRef?.current?.slickPrev()}
          role="button" tabIndex={0}
        />
        {dots}
        <div
          className="slick-arrow-btn slick-next-btn"
          onClick={() => sliderRef?.current?.slickNext()}
          role="button" tabIndex={0}
        />
      </ul>
    ),
  }

  return (
    <ComponentWrapper
      className="image-carousel--full-width-wrapper"
      background={background}
    >
      <TopWrapper>
        {heading && (
          <Heading
            background={background}
            headingSize={headingSize}
          >
            {heading}
          </Heading>
        )}
        {subheading?.subheading && (
          <Subheading>
            <p>{subheading?.subheading}</p>
          </Subheading>
        )}
      </TopWrapper>
      <CarouselWrapper>
        <div className="image-carousel--main-container">
          <Slider ref={sliderRef} {...sliderSettings}>
            {carouselItems?.map((item, index) => (
              <CarouselItems key={index}>
                <CarouselCard className="card-bg">
                  <CarouselText>
                    {item?.heading && <p className="top-text">{item?.heading}</p>}
                    {item?.subheading && (
                      <p className="bottom-text">
                        {switchbackResolveRichText(item?.subheading)}
                      </p>
                    )}
                  </CarouselText>
                </CarouselCard>
              </CarouselItems>
            ))}
          </Slider>
        </div>
      </CarouselWrapper>
    </ComponentWrapper>
  )
}
