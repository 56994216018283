import React, { useState, useEffect, useRef } from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Slider from "react-slick"
import { CarouselTabNavigationColumnWrapper } from "./styles/CarouselTabbedNavigation/CarouselTabbedNavigation.styled"
import useOnScreen from "../../utils/useOnScreen"
import OptimizedImage from "../../utils/optimizedImage"

export const CarouselTabNavigationColumn = ({ component }) => {
  const [selIndex, setSelIndex] = useState(0)
  const imageSliderRef = useRef()
  const wrapperRef = useRef()
  const onScreen = useOnScreen(wrapperRef, '-100px');

  const {
    autoplay,
    heading,
    subheading,
    carouselItems,
    noTopPadding,
    noBottomPadding,
  } = component

  const sliderSettings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    autoplay: autoplay,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    draggable: false,
    swipeToSlide: false,
    speed: 300,
    afterChange: index => setSelIndex(index),
  }

  useEffect(() => {
    if (onScreen) {
      imageSliderRef.current.slickPlay()
    } else {
      imageSliderRef.current.slickPause()
    }
  }, [onScreen])

  return (
    <CarouselTabNavigationColumnWrapper ref={wrapperRef} noTopPadding={noBottomPadding} noBottomPadding={noBottomPadding}>
      <div className="home-guide-section--container">
        <div className="home-guide-section--copy-container">
          <h2 className="home-guide-section--heading">{heading}</h2>
          <p className="home-guide-section--description">
            {subheading.subheading}
          </p>
        </div>
        <div className="home-guide-section--guide-container">
          <div className="home-guide-section--image-container">
            <Slider ref={imageSliderRef} {...sliderSettings}>
              {carouselItems?.map((item, index) => (
                <div key={index}>
                  <div className="home-guide-section--image">
                    <OptimizedImage
                      image={item?.featuredImage?.gatsbyImageData}
                      src={
                        item.featuredImage?.file?.url ||
                        "/images/home-guide-image-04.png"
                      }
                      alt={item?.heading || "featured image"}
                    />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          <div className="home-guide-section--list-container">
            {carouselItems?.map((item, index) => (
              <div
                className={`home-guide-section--list-item ${selIndex === index ? "list-item-selected" : ""
                  }`}
                onClick={(e) => imageSliderRef.current.slickGoTo(index)}
                key={index}
              >
                {item?.heading && <h4>{item?.heading}</h4>}
                {item?.subheading?.raw && (
                  <p>
                    {documentToReactComponents(
                      JSON.parse(item?.subheading?.raw)
                    )}
                  </p>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </CarouselTabNavigationColumnWrapper>
  )
}

CarouselTabNavigationColumn.displayName = "CarouselTabNavigationColumn"

