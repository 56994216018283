import styled from "styled-components"

export const TopWrapper = styled.div`
  text-align: center;
  margin-bottom: 16px;
  font-family: "Roboto";
  font-style: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const Heading = styled.div`
  font-weight: 900;
  font-size: 40px;
  line-height: 60px;
  p {
    max-width: 800px;
    color: #101828;
  }
`

export const CardSubheading = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  padding-bottom: 64px;
  display: flex;
  justify-content: center;
  align-items: center; 
  max-width: 800px;
  opacity: 0;
  margin: 0 auto;
`