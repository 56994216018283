import styled from 'styled-components';

export default styled.div`
  .ReactModalPortal {
    position: relative;
    z-index: 100001;
  }
  .ReactModal__Overlay{
    background-color: rgb(6 9 16 / 92%)!important;
    text-align: center;
  }
  .ReactModal__Content{
    border-radius: 12px!important;
    animation: showModal .2s!important;
    font-family: "Roboto";
    width:970px;
    overflow: initial!important;
    padding:0px!important;
    text-align: left;
    border:none!important;
    @media only screen and (max-width: 991px){
      width:90%;
      height:auto;
    }
    @media only screen and (max-width: 425px){
      width:90%;
      height:auto;
    }
    .close_btn{
      position: absolute;
      top: -30px;
      right: -30px;
      max-width: 16px;
      min-width: 16px;
      height: 16px;
      color: #141619;
      cursor: pointer;
      opacity: .75;
      font-weight: 400;
      font-size: 20px;
      z-index:2;
      @media only screen and (max-width: 768px){
        right: 0px;
      }
    }
    .gatsby-image-wrapper {
      max-width:370px;
      min-width:370px;
      min-height: 100%;
      img {
        height: 100%;
        object-fit:cover;
        object-position:center;
        @media only screen and (max-width: 991px){
          max-height:100%;
        }
        @media only screen and (max-width: 768px){
          max-width:170px;
          min-width:170px;
          border-radius: 6px;
        }
      }
    }
    .modal-body{
      display: flex;
      padding:0!important;
      border-radius: 12px !important;
      overflow: hidden;
      @media only screen and (max-width: 768px){
        display: block;
        text-align: center;
        padding: 24px !important;
      }
    }
    .modal-desc{
      padding:100px;
      display: inline-block;
      @media only screen and (max-width: 991px){
        padding: 40px;
      }
      @media only screen and (max-width: 768px){
        padding: 40px 0;
      }
      .modal-name{
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 34px;
        letter-spacing: -0.3px;
        color: #151A30;
        margin-bottom:8px;
      }
      .modal-bio{
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #475467;
        opacity: 0.8;
        margin-bottom:0;
      }
      button {
        margin-top: 32px;
        @media only screen and (max-width: 768px){
          width: 100%;
        }
      }
    }
  }
  @keyframes showModal {
    0% {
      transform: scale(1) translate(-50%,-50%);
    }
    50% {
      transform: scale(1.05) translate(-50%,-50%);
    }
    100% {
      transform: scale(1) translate(-50%,-50%);
    }
  }
`
