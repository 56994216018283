import styled, { css } from "styled-components"

export const CarouselText = styled.div`
  font-family: "Roboto";
  font-style: normal;
  padding-top: 12px;
  .top-text {
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
  }
  .bottom-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding-top: 4px;
  }
`

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const CarouselImage = styled.div`
  width: 170px;
  height: 170px;
  display: block;
  overflow: hidden;
  display: flex;
  border-radius: 6px;
  position: relative;
  cursor: pointer;
  &:before {
    content: 'Read Bio';
    font-family: "Roboto";
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    color: #FFF;
    width: 100%;
    height: 100%;
    padding: 43% 0;
    text-align: center;
    border-radius: 6px;
    position: absolute;
    top: 0;
    left: 0;
    background: #002dc2b5;
    opacity: 0;
    transition: 0.3s;
  }
  &:hover {
    &:before {
      opacity: 1;
    }
  }
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`

export const CarouselCard = styled.div`
  height: 240px;
  width: 170px;
  margin-left: 15px;
  margin-right: 15px;
`

export const CarouselItems = styled.div`
  margin-bottom: 75px;
`

export const SlidesWrapper = styled.div``

export const TopWrapper = styled.div`
  text-align: center;
  margin-bottom: 50px;
  font-family: "Roboto";
  font-style: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const Heading = styled.div`
  font-weight: 700;
  font-size: 35px;
  line-height: 48px;
  p {
    max-width: 800px;
  }
`

export const Subheading = styled.div`
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  padding-top: 24px;
  color: #475467;
  p {
    max-width: 800px;
  }
`

export const CarouselWrapper = styled.div`
  background-image: ${props =>
    `url(${props.backgroundImage?.primaryImage?.file?.url})`};
  background-size: contain;
  background-repeat: no-repeat;
  overflow:hidden;
  @media (min-width: 1700px) {
    background-size: cover;
  }
  padding-top: 96px;
  padding-bottom: 96px;
  ${({ noTopPadding, noBottomPadding }) => css`
    padding-top: ${noTopPadding === true && 0};
    padding-bottom: ${noBottomPadding === true && 0};
    @media (max-width: 992px) {
      padding-top: ${noTopPadding === true && 0};
      padding-bottom: ${noBottomPadding === true && 0};
    }
    @media (max-width: 768px) {
      padding-top: ${noTopPadding === true && 0};
      padding-bottom: ${noBottomPadding === true && 0};
    }
  `}
  .slick-slider {
    .slick-dots {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;

      li {
        width: 10px;
        height: 10px;
        margin: 0 8px;

        button {
          width: 10px;
          height: 10px;
          padding: 0;

          &:before {
            width: 10px;
            height: 10px;
            line-height: 10px;
            font-size: 10px;
          }
        }
      }
    }

    .slick-arrow-btn {
      width: 30px;
      height: 30px;
      background: #0022b7;
      border-radius: 50%;
      margin: 0 10px;
      cursor: pointer;

      &:before {
        display: block;
        content: " ";
        background-image: url("/icons/chevron-right-white-thin.svg");
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
      }
      &.slick-prev-btn {
        &:before {
          transform: rotate(180deg);
        }
      }
      &.slick-next-btn {
      }
    }
  }
`
