import styled, { css } from "styled-components";
import { color } from "../../../atoms/colors";
import { font } from "../../../atoms/typography";

export const CarouselWrap = styled.div`
  background-color: ${({ bgColor }) => (bgColor === "grey" ? color.grey[100] : color.common.white)};
  margin: 0 auto;
  padding: 0 0 96px;
  @media (max-width: 992px) {
    padding: 0 0 56px;
  }
  @media (max-width: 576px) {
    padding: 0 0 40px;
  }
  ${({ noBottomPadding }) => css`
    padding-bottom: ${noBottomPadding === true && 0};
    @media (max-width: 992px) {
      padding-bottom: ${noBottomPadding === true && 0};
    }
    @media (max-width: 768px) {
      padding-bottom: ${noBottomPadding === true && 0};
    }
  `}
`

export const SliderWrap = styled.div`
  padding: 12px 0 0;
  height: 100%;
  .slick-slider {
    .slick-dots {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      position: relative;
      bottom: 0;
      width: 112px;
      margin: 0 auto;
      .slick-arrow {
        width: 44px;
        height: 44px;
        margin: 0 24px;
        cursor: pointer;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        &:before {
          display: block;
          content: " ";
          width: 32px;
          height: 32px;
          background: #0022b7;
          border-radius: 50%;
          background-image: url("/icons/chevron-right-white.svg");
          background-repeat: no-repeat;
          background-position: 53%;
          opacity: 1!important;
        }
        &.slick-prev {
          &:before {
            transform: rotate(180deg);
          }
        }
        &.slick-next {
        }
      }
    }

  }
  .slick-track {
    display: flex!important;
    padding: 32px 0;
  }
  .slick-slide {
    height: inherit!important;
  }
  .slick-slide > div {
    margin: 0 15px;
    height: 100%;
    display: flex;
    a {
      text-decoration: none;
      border-radius: 8px;
      transition: box-shadow 250ms ease;
      &:hover {
        box-shadow: 0px 10px 30px 0px rgba(16, 24, 40, 0.08);
      }
    }
  }
`

export const IconCardWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: 370px;
  height: 100%;
  border-radius: 8px;
  border: 2px solid ${color.grey[200]};
  background: ${color.common.white};
  padding: 24px;
  .icon {
    width: 64px;
    height: 64px;
  }
`

export const IconCardHeading = styled.span`
  ${font('display', 'xxs', '700')}
  color: ${color.common.black};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const IconCardSubheading = styled.span`
  ${font('text', 'reg', '400')}
  color: ${color.grey[600]};
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  p {
    margin-bottom: 0!important;
  }
`

export const IconCardLink = styled.span`
  ${font('text', 'reg', '700')}
  color: ${color.common.workgridBlue};
`
