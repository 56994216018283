import React from "react";
import { Link } from "gatsby";
import OptimizedImage from "../../../utils/optimizedImage";
import { switchbackResolveRichText } from '@utils/resolve-rich-text/switchbackResolveRichText';
import { IconCardHeading, IconCardLink, IconCardSubheading, IconCardWrap } from "../styles/CarouselIconVariant.styled";
import { ConditionalWrapper } from "../../../atoms/ConditionalWrapper";

const IconVariantCard = ({ item }) => {
  const hasLink = item?.callsToAction !== null

  return (
    <ConditionalWrapper
      condition={hasLink}
      wrapper={children => (
        <Link tabIndex={0} to={item?.callsToAction[0]?.link}>{children}</Link>
      )}
    >
      <IconCardWrap>
        {item?.icon && <OptimizedImage className="icon" src={item?.icon?.file?.url} image={item?.icon?.gatsbyImageData} alt={item?.icon?.alt || `${item?.heading} icon`} />}
        {item?.heading && <IconCardHeading>{item?.heading}</IconCardHeading>}
        {item?.subheading && <IconCardSubheading>{switchbackResolveRichText(item?.subheading)}</IconCardSubheading>}
        {hasLink && <IconCardLink>Learn more</IconCardLink>}
      </IconCardWrap>
    </ConditionalWrapper>
  )
}

export default IconVariantCard;
