import React from "react"
import {
  CarouselContentBlockWrapper,
  SectionContainer,
  CopyContainer,
  SectionHeading,
  SectionDescription,
  FeaturedContainer,
  FeaturedWrapper,
} from "./styles/CarouselContentBlock.styled"
import Slider from "react-slick"
import ContentBlockItem from "./components/ContentBlockItem"

export const CarouselContentBlock = ({ component }) => {
  const {
    id,
    autoplay,
    heading,
    subheading,
    carouselItems,
    noTopPadding,
    noBottomPadding,
  } = component

  const sliderSettings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    autoplay: autoplay,
    autoplaySpeed: 6000,
    pauseOnHover: true,
    draggable: true,
    swipeToSlide: true,
    speed: 2000,
  }

  return (
    <CarouselContentBlockWrapper id={id} noTopPadding={noTopPadding} noBottomPadding={noBottomPadding}>
      <SectionContainer>
        <CopyContainer>
          {heading && <SectionHeading>{heading}</SectionHeading>}
          {subheading?.subheading && <SectionDescription>
            {subheading?.subheading}
          </SectionDescription>}
        </CopyContainer>
        <FeaturedContainer>
          <FeaturedWrapper>
            <Slider {...sliderSettings}>
              {carouselItems?.map(item => <ContentBlockItem item={item} />)}
            </Slider>
          </FeaturedWrapper>
        </FeaturedContainer>
      </SectionContainer>
    </CarouselContentBlockWrapper>
  )
}

CarouselContentBlock.displayName = "CarouselContentBlock"
