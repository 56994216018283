import React, { useState, createRef } from "react"
import Modal from 'react-modal'
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import { switchbackResolveRichText } from "@utils/resolve-rich-text/switchbackResolveRichText"
import { defaultResolveRichText } from "@utils/resolve-rich-text/defaultResolveRichText"
import OptimizedImage from "@utils/optimizedImage"
import ModalStyles from '../../styles/modal';
import closeBtn from '../../assets/icons/close.svg'
import { CarouselText, ImageContainer, CarouselImage, CarouselCard, CarouselItems, SlidesWrapper, TopWrapper, Heading, Subheading, CarouselWrapper, } from "./styles/CarouselSmallImage.styled"

export const CarouselSmallImage = ({ component, backgroundColor }) => {
  const {
    heading,
    subheadingTest,
    carouselItems,
    autoplay,
    backgroundImage,
    noTopPadding,
    noBottomPadding,
  } =
    component
  const sliderRef = createRef()
  const [modalIsOpen, setIsOpen] = useState(false)
  const [userInfo, setUserInfo] = useState(null)

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
    },
  }

  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 2,
    centerMode: true,
    autoplay: autoplay === false ? false : true,
    draggable: true,
    swipeToSlide: true,
    variableWidth: true,
    speed: 1000,
    appendDots: dots => (
      <div>
        <div
          className="slick-arrow-btn slick-prev-btn"
          onClick={() => sliderRef?.current?.slickPrev()}
          role="button" tabIndex={0}
        />
        <div
          className="slick-arrow-btn slick-next-btn"
          onClick={() => sliderRef?.current?.slickNext()}
          role="button" tabIndex={0}
        />
      </div>
    ),
  }

  const handleDetail = (item) => {
    setUserInfo(item);
    setIsOpen(true);
  }

  return (
    <CarouselWrapper backgroundImage={backgroundImage} noTopPadding={noTopPadding} noBottomPadding={noBottomPadding}>
      <TopWrapper>
        <Heading>{heading && <p>{heading}</p>}</Heading>
        <Subheading>
          {subheadingTest?.raw && (
            <p>{switchbackResolveRichText(subheadingTest)}</p>
          )}
        </Subheading>
      </TopWrapper>
      <SlidesWrapper>
        <Slider ref={sliderRef} {...sliderSettings}>
          {carouselItems?.map((item, index) => (
            <CarouselItems key={item?.id || `Slider Image ${index}`}>
              <CarouselCard
                onClick={() => handleDetail(item)}
                role="button" tabIndex={0}
              >
                <ImageContainer>
                  <CarouselImage>
                    <OptimizedImage
                      alt={item?.heading}
                      src={item?.featuredImage?.file?.url}
                      image={item?.featuredImage?.gatsbyImageData}
                    />
                  </CarouselImage>
                </ImageContainer>
                <CarouselText>
                  {item?.heading && <p className="top-text">{item?.heading}</p>}
                  {item?.subheading && (
                    <p className="bottom-text">
                      {switchbackResolveRichText(item?.subheading)}
                    </p>
                  )}
                </CarouselText>
              </CarouselCard>
            </CarouselItems>
          ))}
        </Slider>
      </SlidesWrapper>
      <ModalStyles id="modal-style">
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => setIsOpen(false)}
          style={customStyles}
          parentSelector={() => document.querySelector('#modal-style')}
        >
          <span onClick={() => setIsOpen(false)} role="button" tabIndex={0}>
            <OptimizedImage
              src={closeBtn}
              className="close_btn"
              alt='Modal Close icon'
            />
          </span>
          <div className="modal-body">
            {userInfo?.featuredImage?.file &&
              <OptimizedImage
                src={userInfo?.featuredImage?.file?.url}
                alt={userInfo?.featuredImage?.title}
                image={userInfo?.featuredImage?.gatsbyImageData}
              />
            }
            <div className="modal-desc">
              {userInfo?.heading && (
                <p className="modal-name">{userInfo?.heading}</p>
              )}
              {userInfo?.subheading && <p className="modal-role">{switchbackResolveRichText(userInfo?.subheading)}</p>}
              {userInfo?.bodyCopy && (
                <div className="modal-bio">{defaultResolveRichText(userInfo?.bodyCopy)}</div>
              )}
            </div>
          </div>
        </Modal>
      </ModalStyles>
    </CarouselWrapper>
  )
}
