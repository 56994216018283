import styled, { css } from "styled-components"

export const CarouselTabNavigationColumnWrapper = styled.div`
  padding: 96px 60px;
  font-family: "Roboto";
  position: relative;
  background: #ffffff;
  @media (max-width: 768px) {
    padding: 64px 48px;
  }
  @media (max-width: 576px) {
    padding: 48px 20px;
  }
  ${({ noTopPadding, noBottomPadding }) => css`
    padding-top: ${noTopPadding === true && 0};
    padding-bottom: ${noBottomPadding === true && 0};
    @media (max-width: 992px) {
      padding-top: ${noTopPadding === true && 0};
      padding-bottom: ${noBottomPadding === true && 0};
    }
    @media (max-width: 768px) {
      padding-top: ${noTopPadding === true && 0};
      padding-bottom: ${noBottomPadding === true && 0};
    }
  `}
  .home-guide-section--container {
    position: relative;
  }
  .home-guide-section--copy-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .home-guide-section--heading {
      font-weight: 900;
      font-size: 50px;
      line-height: 60px;
      text-align: center;
      color: #1b1b1b;
      max-width: 770px;
      @media (max-width: 768px) {
        font-size: 44px;
        line-height: 48px;
      }
      @media (max-width: 576px) {
        font-size: 38px;
        line-height: 44px;
      }
    }
    .home-guide-section--description {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #475467;
      margin: 16px 0 0;
      max-width: 770px;
    }
  }
  .home-guide-section--guide-container {
    max-width: 1167px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 56px auto 0;
    @media (max-width: 768px) {
      flex-direction: column-reverse;
      margin-top: 36px;
    }
    .home-guide-section--image-container {
      width: 70%;
      max-width: 800px;
      height: 600px;
      @media (max-width: 768px) {
        width: 100%;
        max-width: 100%;
        height: auto;
        margin: 24px 0 0;
      }
      .home-guide-section--image {
        width: 100%;
        img {
          max-width: 600px;
          height: auto;
          margin: 0 auto;
          @media (max-width: 767.98px) {
            max-width: 100%;
          }
        }
      }
    }
    .home-guide-section--list-container {
      width: 30%;
      margin-left: 30px;
      max-width: 300px;
      display: flex;
      flex-direction: column;
      @media (max-width: 768px) {
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        max-width: 100%;
        margin: 0;
      }
      .home-guide-section--list-item {
        padding: 15px 0;
        cursor: pointer;
        width: 100%;
        @media (max-width: 768px) {
          width: 50%;
          padding: 15px;
        }
        @media (max-width: 576px) {
          width: 100%;
          padding: 15px 0;
        }
        h4 {
          font-weight: bold;
          font-size: 28px;
          line-height: 34px;
          letter-spacing: 0.1px;
          color: #98a2b3;
          @media (max-width: 576px) {
            font-size: 24px;
            line-height: 32px;
          }
        }
        p {
          font-size: 16px;
          line-height: 24px;
          color: #98a2b3;
          margin: 10px 0 0;
        }
        &.list-item-selected {
          h4 {
            color: #0022b7;
          }
          p {
            color: #1b1b1b;
          }
        }
      }
    }
    .slick-slider {
      .slick-dots {
        bottom: -64px;
        @media (max-width: 576px) {
          bottom: -25px;
        }
        li {
          width: 14px;
          height: 14px;
          margin: 0 10px;
          button {
            width: 14px;
            height: 14px;
            padding: 0;
            border: 1px solid #fff;
            border-radius: 50%;
            &:before {
              font-size: 14px;
              line-height: 16px;
              width: 14px;
              height: 14px;
              color: #e4e7ec;
              opacity: 1;
            }
          }
          &.slick-active {
            button:before {
              color: #0022b7;
            }
          }
        }
      }
    }
  }
`
